import styled from "styled-components/";

import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedSectionSubTitle,
  SharedSectionTitle,
} from "../../components/Typography";

const Container = styled(SharedContainer)`
  background-image: none;
`;

const Content = styled(SharedContent)`
  max-width: 1200px;
  width: 100%;
  padding: 80px 40px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ListContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
`;

const ListItem = styled.div`
  background-image: linear-gradient(to bottom right, #8b76eb, #90c9fa);
  padding: 20px;
  width: 300px;
  height: 360px;
  margin: 7px;
  box-shadow: 2px 1px 20px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 708px) {
    width: 100%;
  }
`;
const ListTitle = styled.p`
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #64e494;
`;
const ListSubTitle = styled.p`
  margin: -16px 0;
  font-size: 14px;
`;
const ListDesc = styled.p`
  font-size: 14px;
  margin-top: 40px;
  line-height: 18px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
`;
const ListIcon = styled.img`
  width: 90px;
  height: 90px;
  object-fit: contain;
  border-radius: 80px;
  border: 4px solid rgba(255, 255, 255, 0.3);
`;

const LIST = [
  {
    icon: "/clients/emac.jpeg",
    title: "Eoin Machale",
    subTitle: "CEO, Largum",
    desc: "We hired Intosoft to work with us to build a geo location app for iOS and Android. We were impressed with Sakul and his team of developers. The project had many challenging technical issues that Intosoft found ways to overcome. I would highly recommend Intosoft for native app development",
  },
  {
    icon: "/clients/colin.jpeg",
    title: "Colin Travis",
    subTitle: "CEO, Rentisity",
    desc: "Sakul and his team developed our MVP for iOS and web and we were pleased with how Intosoft could turn a general brief into a working product. Bug fixing were turned around quickly which allowed us to continue to test the application and make improvements in an agile fashion which helped improve the overall project.",
  },
];

export default function Testimonials() {
  return (
    <Container>
      <Content>
        <SharedSectionSubTitle>TESTIMONIALS</SharedSectionSubTitle>
        <SharedSectionTitle>What our client says to us</SharedSectionTitle>
        <ListContainer>
          {LIST.map((listItem) => (
            <ListItem key={listItem.title}>
              <ListIcon src={listItem.icon} />
              <ListTitle>{listItem.title}</ListTitle>
              <ListSubTitle>{listItem.subTitle}</ListSubTitle>
              <ListDesc>{listItem.desc}</ListDesc>
            </ListItem>
          ))}
        </ListContainer>
      </Content>
    </Container>
  );
}
