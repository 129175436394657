import React from "react";
import "../styles/Menu.css";
import { GrFormClose } from "react-icons/gr";
import { LINKS } from "./Header";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
interface MobileSidebarProps {
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
}
const MobileLink = styled.div`
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #64e494;
`;
const MobileSidebar: React.FC<MobileSidebarProps> = ({
  showMenu,
  setShowMenu,
}) => {
  return (
    <>
      <div className={`sidebar ${showMenu === true ? "active" : ""}`}>
        <div className="sd-header">
          <div
            className="btn btn-primary"
            onClick={() => setShowMenu(!showMenu)}
          >
            <GrFormClose style={{ fontSize: "30px" }} className="Xclose" />
          </div>
        </div>
        <div className="sd-body">
          {LINKS.map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              onClick={() => setShowMenu(false)}
            >
              <MobileLink>{link.title}</MobileLink>
            </NavLink>
          ))}
        </div>
      </div>
      <div
        className={`sidebar-overlay ${showMenu === true ? "active" : ""}`}
        onClick={() => setShowMenu(!showMenu)}
      ></div>
    </>
  );
};

export default MobileSidebar;
