import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  
    body {
        margin: 0;
        padding: 0;
        background-color: #f5faff;
        overflow-x:hidden;
        &::-webkit-scrollbar {
display: none;
}
  }
  * {
    box-sizing: border-box;
   
    font-family: "Roboto", sans-serif;
  }

  a, a:active, a:hover, a:visited {
    color:black;
    text-decoration: none;
  
  }

  .react-images__view-image--isModal {
    
    max-height: 100vh !important;
    border: 10px red;
    width: auto !important;
    max-width: auto !important;
  }
`;
