import React from "react";
import Banner from "./Banner";

const ServicesData = [
  {
    id: 1,
    title: "Application developement",
    desc: "Bring the Latest Mobile Technology under your finger tips and enhancing your business capability with custom applications",
    image: "illustrations/growing.png",
  },
  {
    id: 2,
    title: "Software Services",
    desc: "Custom software development services, including web and mobile application development, to help you automate business processes, improve data management and analysis, and enhance customer engagement.",
    image: "illustrations/brainstorming.png",
  },
  {
    id: 3,
    title: "Technical Support",
    desc: "Help you move your data and applications to the cloud for increased scalability, flexibility, and cost savings. And Help you connect with your target audience and boost conversions",
    image: "illustrations/planning.png",
  },
];
const Services = () => {
  return (
    <div>
      {ServicesData.map((item) => (
        <Banner item={item} />
      ))}
    </div>
  );
};

export default Services;
