import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedSectionSubTitle,
  SharedSectionTitle,
} from "../../components/Typography";
const Container = styled(SharedContainer)`
  background-image: none;
`;

const Content = styled(SharedContent)`
  padding-top: 60px;
  padding-bottom: 60px;
  flex-direction: column;
  text-align: center;
`;

const LocationInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0px;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const Illustration = styled.img`
  height: 350px;
  z-index: 1;
`;
const BiggerIllustration = styled.img`
  height: 500px;
  @media only screen and (max-width: 768px) {
    height: 350px;
  }
`;
const ListTitle = styled.p`
  font-weight: bold;
  font-family: "Poppins", sans-serif;
`;
const ListDesc = styled.p`
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 1.1px;
  line-height: 18px;
`;
const GreenDesc = styled.span`
  font-family: "Poppins", sans-serif;
  color: #64e494;
`;
const BiggerText = styled.div`
  font-family: "Poppins", sans-serif;
`;
const BiggerWrapper = styled.div`
  //   position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const BListTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  line-height: 45px;
  color: #64e494;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    font-size: 35px;
  }
`;

export default function WhereWeAre() {
  return (
    <Container>
      <Content>
        <SharedSectionSubTitle>WHERE WE ARE</SharedSectionSubTitle>
        <SharedSectionTitle>Our Location & Contact</SharedSectionTitle>
        <LocationInfo>
          <div>
            <ListTitle>Into Soft Pvt. Ltd.</ListTitle>
            <ListDesc>Narephat, Koteshwor-32, Kathmandu</ListDesc>
            <ListDesc>
              Phone - <GreenDesc> +977-9769761859</GreenDesc>
            </ListDesc>
            <ListDesc>
              Email - <GreenDesc>hi@intosoft.com</GreenDesc>
            </ListDesc>
          </div>
          <Illustration src="/illustrations/address.png" />
        </LocationInfo>
        <BiggerWrapper>
          <BiggerIllustration src="/illustrations/revenue.png" />
          <BiggerText>
            <ListTitle>Looking to be a part of our team?</ListTitle>
            <br />
            <BListTitle>Let’s build better and grow together</BListTitle>
          </BiggerText>
        </BiggerWrapper>
      </Content>
    </Container>
  );
}
