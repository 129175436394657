import React from "react";
import styled from "styled-components";
import { SharedContent, SharedContainer } from "../../components/Layout";

import {
  SharedSectionSubTitle,
  SharedSectionTitle,
} from "../../components/Typography";

import { ReactPhotoCollage } from "react-photo-collage";

const Container = styled(SharedContainer)`
  background: white !important;

  [data-id="1"] {
    div {
      background-position: top !important;
    }
  }

  [data-id="3"] {
    div {
      background-position: center 15% !important;
    }
  }
`;

const Content = styled(SharedContent)`
  max-width: 1200px;
  width: 100%;
  margin: 80px auto;

  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const setting = {
  width: "80%",
  height: ["300px", "200px"],
  layout: [2, 2, 1],
  photos: [
    {
      source: "teams/team.jpg",
    },
    {
      source: "teams/group.jpg",
    },
    {
      source: "teams/hardworking.jpg",
    },
    {
      source: "teams/help1.jpg",
    },
    {
      source: "teams/meeting.jpg",
    },
  ],
};

const Team = () => {
  return (
    <Container>
      <Content>
        <SharedSectionSubTitle>Our Team</SharedSectionSubTitle>
        <SharedSectionTitle>Teamwork makes the dream work!</SharedSectionTitle>
        <ReactPhotoCollage {...setting} />
      </Content>
    </Container>
  );
};

export default Team;
