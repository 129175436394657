import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import Careers from "./Careers";
import CareersView from "./CareersView";
import CaseStudies from "./CaseStudies";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";
import ScrollToTop from "../components/ScrollToTop";
import GetInTouch from "./GetInTouch";
import Services from "./Services";

const Container = styled.div``;

export default function RoutesComp() {
  return (
    <Router>
      <ScrollToTop />
      <Container>
        <Header />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={Homepage()} />
          <Route path="/careers" element={Careers()} />
          <Route path="/careers/:id" element={CareersView()} />
          <Route path="/case-studies" element={CaseStudies()} />
          <Route path="/get-in-touch" element={GetInTouch()} />
          <Route path="/services" element={Services()} />
          <Route path="*" element={Homepage()} />
        </Routes>
        <Footer />
      </Container>
    </Router>
  );
}
