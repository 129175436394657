import styled from "styled-components";
import React from "react";

const Header = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
`;
const HeaderInner = styled.div`
  max-width: 1200px;
`;

const Logo = styled.img``;

export default function CaseStudies() {
  return (
    <Header>
      <HeaderInner>
        <Logo src="/logo-white.png" alt="Intosoft logo" />
      </HeaderInner>
    </Header>
  );
}
