import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedBannerTitle,
  SharedBannerSubTitle,
} from "../../components/Typography";

const Container = styled(SharedContainer)`
  height: 590px;
`;

const Content = styled(SharedContent)`
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  height: 2px;
  width: 70px;
  background-color: #64e494;
`;

const GreenDesc = styled.span`
  font-family: "Poppins", sans-serif;
  color: #64e494;
`;

const Illustration = styled.img`
  height: 400px;
  z-index: 1;
  @media only screen and (max-width: 974px) {
    display: none;
  }
`;

export default function Banner() {
  return (
    <Container>
      <Content>
        <div
          style={{
            position: "relative",
          }}
        >
          <SharedBannerSubTitle>YOUR DEVELOPMENT PARTNER</SharedBannerSubTitle>
          <Line />
          <SharedBannerTitle>
            Complete IT Solution To <GreenDesc>Grow Your Business</GreenDesc>
          </SharedBannerTitle>
        </div>
        <Illustration src="illustrations/home.png" />
      </Content>
    </Container>
  );
}
