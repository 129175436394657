import React from "react";
import Banner from "./Banner";
import WhereWeAre from "./WhereWeAre";

const GetInTouch = () => {
  return (
    <div>
      <Banner />
      <WhereWeAre />
    </div>
  );
};

export default GetInTouch;
