import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedSectionTitle,
  SharedSectionSubTitle,
} from "../../components/Typography";

const projects = [
  {
    title: "AWS",
    image: "/tools/aws.png",
  },
  {
    title: "React Native",
    image: "/tools/react.png",
  },
  {
    title: "Node.js",
    image: "/tools/nodejs.png",
  },

  {
    title: "Firebase",
    image: "/tools/firebase.png",
  },
  {
    title: "Ruby",
    image: "/tools/ruby.png",
  },
  {
    title: "MySQL",
    image: "/tools/mysql.png",
  },
  {
    title: "GCP",
    image: "/tools/gcp.png",
  },
  {
    title: "Electron",
    image: "/tools/electronjs.png",
  },

  {
    title: "Ruby on Rails",
    image: "/tools/rails.png",
  },
  {
    title: "MongoDB",
    image: "/tools/mongo.png",
  },
  {
    title: "PostgreSQL",
    image: "/tools/postgres.png",
  },

  {
    title: "Heroku",
    image: "/tools/heroku.png",
  },
  {
    title: "React",
    image: "/tools/react.png",
  },
];

const Container = styled(SharedContainer)`
  padding-top: 110px;
  background-image: linear-gradient(to bottom right, #5d36df, #38a3e3);
  background: white;
  overflow: hidden;
`;

const Content = styled(SharedContent)`
  text-align: center;
  flex-direction: column;
`;

const ProjectListContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @keyframes marqueeLeft {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
`;
const ListItem = styled.div`
  margin: 10px 28px;
  width: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ListImage = styled.img`
  height: 60px;
  object-fit: contain;
  object-position: top;
  border-radius: 4px;
`;
const ListTitle = styled.p`
  color: black;
  font-family: "Poppins", sans-serif;
`;

export default function Tools() {
  return (
    <Container>
      <Content>
        <div
          style={{
            position: "relative",
          }}
        >
          <SharedSectionSubTitle>TOOLS</SharedSectionSubTitle>

          <SharedSectionTitle>
            Technologies we use to build great products
          </SharedSectionTitle>
        </div>
        <ProjectListContainer>
          <ProjectList>
            {projects.map((item) => (
              <ListItem key={item.title}>
                <ListImage src={item.image} />
                <ListTitle>{item.title}</ListTitle>
              </ListItem>
            ))}
          </ProjectList>
        </ProjectListContainer>
      </Content>
    </Container>
  );
}
