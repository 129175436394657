import Banner from "./Banner";
import Hiring from "./Hiring";
import Tools from "./Tools";
import { useEffect } from "react";
import Team from "../Homepage/Team";

export default function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Banner />
      <Hiring />
      <Team />
      <Tools />
    </div>
  );
}
