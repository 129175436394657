import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedSectionTitle,
  SharedSectionSubTitle,
  SharedListTitle,
} from "../../components/Typography";

const Container = styled(SharedContainer)``;

const Content = styled(SharedContent)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const ProjectList = styled.div`
  display: flex;
  flex-direction: row;
`;
const ListItem = styled.div`
  margin: 10px;
`;

const ListImageContainer = styled.div`
  border-radius: 4px;
  border: 14px solid rgba(255, 255, 255, 0.2);
`;
const ListImage = styled.img`
  height: 500px;
  width: 390px;
  object-fit: cover;
  object-position: top;
  border-radius: 4px;
  @media only screen and (max-width: 900px) {
    width: 40vw;
    height: calc(40vw * 1.78);
  }
`;

const projects = [
  {
    title: "Rentisty web",
    image: "/projects/rentisity-web.png",
  },
  {
    title: "Rentisty mobile",
    image: "/projects/rentisity-mobile.png",
  },
];

export default function Projects() {
  return (
    <Container>
      <Content>
        <div
          style={{
            position: "relative",
          }}
        >
          <SharedSectionSubTitle>OUR</SharedSectionSubTitle>
          <SharedSectionTitle color="white">Projects</SharedSectionTitle>
        </div>

        <ProjectList>
          {projects.map((item) => (
            <ListItem key={item.title}>
              <ListImageContainer>
                <ListImage src={item.image} />
              </ListImageContainer>
              <SharedListTitle>{item.title}</SharedListTitle>
            </ListItem>
          ))}
        </ProjectList>
      </Content>
    </Container>
  );
}
