import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedSectionSubTitle,
  SharedSectionTitle,
} from "../../components/Typography";
const Container = styled(SharedContainer)`
  background-image: none;
`;

const Content = styled(SharedContent)`
  padding-top: 140px;
  padding-bottom: 60px;
  flex-direction: column;
  text-align: center;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const List = styled.div`
  display: inline-flex;
  margin-top: 60px;
  flex-wrap: wrap;
  width: 793px;
  @media only screen and (max-width: 873px) {
    width: 537px;
  }

  @media only screen and (max-width: 607px) {
    justify-content: center;
  }
`;

const ListItem = styled.div`
  background-color: white;
  padding: 20px;
  width: 250px;
  height: 270px;
  margin: 7px;
  box-shadow: 2px 1px 20px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  @media only screen and (max-width: 607px) {
    width: 100%;
  }
`;
const ListTitle = styled.p`
  font-weight: bold;
  font-family: "Poppins", sans-serif;
`;
const ListDesc = styled.p`
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 1.1px;
  line-height: 18px;
`;
const ListIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

const LIST = [
  {
    icon: "/icons/mobile.png",
    title: "Mobile App Development",
    desc: "Enterprise mobility solutions for your business, extend your possibility with mobile apps to reach new heights. ",
  },
  {
    icon: "/icons/web.png",
    title: "Web App Development",
    desc: "Cutting edge web app development, turn your idea into reality. We deliver fullstack development.",
  },
  {
    icon: "/icons/desktop.png",
    title: "Desktop App ",
    desc: "Reach to your customer on every device and OS. Offline capability will make things easier for your costumers. ",
  },
  {
    icon: "/icons/brand.png",
    title: "Graphic Design",
    desc: "Stand out your profile, product and services with clean and modern designs. We are experts for UI/UX and graphics related tasks.",
  },
  {
    icon: "/icons/consulting.png",
    title: "IT consulting ",
    desc: "Do you need support or have any questions related to IT? We can help you understand things and reach to a conclusion. ",
  },
];

export default function WhatWeDo() {
  return (
    <Container>
      <Content>
        <SharedSectionSubTitle>WHAT WE DO</SharedSectionSubTitle>
        <SharedSectionTitle>Information & Technology</SharedSectionTitle>
        <ListContainer>
          <List>
            {LIST.map((listItem) => (
              <ListItem key={listItem.title}>
                <ListIcon src={listItem.icon} />
                <ListTitle>{listItem.title}</ListTitle>
                <ListDesc>{listItem.desc}</ListDesc>
              </ListItem>
            ))}
          </List>
        </ListContainer>
      </Content>
    </Container>
  );
}
