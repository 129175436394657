import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";

const Container = styled(SharedContainer)`
  background-image: none;
`;

const Content = styled(SharedContent)`
  padding-top: 60px;
  padding-bottom: 60px;
  flex-direction: column;
  margin-top: -100px;
  background-color: white;
  border-radius: 10px;
  z-index: 9;

  p {
    margin-bottom: -5px;
  }
`;

const Mail = styled.span`
  margin-bottom: 10px;
  a {
    color: #46e08d;
  }
`;
export default function Benefits() {
  return (
    <Container>
      <Content>
        <Mail>
          Send us your updated resume at{" "}
          <a href="mailto: careers@intosoft.com">careers@intosoft.com</a>
        </Mail>

        <p>Working hours: Mon-Fri (9 AM - 6:00 PM)</p>
        <p>Paid Leaves</p>
        <p>Lunch provided</p>
      </Content>
    </Container>
  );
}
