import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";

const Container = styled(SharedContainer)`
  height: 390px;
`;

const Content = styled(SharedContent)`
  justify-content: space-between;
  align-items: center;
`;

const Illustration = styled.img`
  height: 400px;
  z-index: 1;
  @media only screen and (max-width: 974px) {
    display: none;
  }
`;

export default function Banner() {
  return (
    <Container>
      <Content>
        <div
          style={{
            position: "relative",
          }}
        >
          {/* <SharedBannerSubTitle>CAREERS</SharedBannerSubTitle>
          <Line />
          <SharedBannerTitle>
            Get The Right <GreenDesc>Job You Deserve</GreenDesc>
          </SharedBannerTitle> */}
        </div>
        <Illustration src="/illustrations/career.png" />
      </Content>
    </Container>
  );
}
