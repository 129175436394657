import styled from "styled-components";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SharedContainer, SharedContent } from "./Layout";
import { useMediaQuery } from "react-responsive";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileSidebar from "./MobileSidebar";

const Container = styled(SharedContainer)<{
  isScrolled: boolean;
}>`
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  background-image: none;
  background-color: ${({ isScrolled }) =>
    isScrolled ? "white" : "rgba(255,255,255,0)"};
  transition: background 0.1s ease-out;
  z-index: 999;

  a,
  a:active,
  a:hover,
  a:visited {
    color: ${({ isScrolled }) => (isScrolled ? "black" : "white")};
  }

  a:hover {
    text-decoration: underline;
  }
`;
const Content = styled(SharedContent)`
  justify-content: space-between;
  align-items: center;
  > a {
    margin: 0;
    padding: 0;
  }
  @media only screen and (max-width: 612px) {
    padding: 10px 35px;
  }
`;

const Logo = styled.img`
  height: 40px;
  transition: 0.5s;
`;

const RightContainer = styled.div`
  a {
    margin-left: 40px;
  }
`;
export const LINKS = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "Careers",
    to: "/careers",
  },
  {
    title: "Services",
    to: "/services",
  },
  {
    title: "Get In Touch",
    to: "/get-in-touch",
  },
];

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  console.log("showmenu---", showMenu);
  const isMobile = useMediaQuery({ query: "(max-width:612px)" });
  const [isScrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 40) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Container isScrolled={isScrolled}>
      <Content>
        <NavLink to="/">
          <Logo
            src={isScrolled ? "/logo.png" : "/logo-white.png"}
            alt="Intosoft logo"
          />
        </NavLink>
        <RightContainer>
          {isMobile && showMenu ? (
            <MobileSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
          ) : isMobile ? (
            <GiHamburgerMenu
              style={{ fontSize: "30px" }}
              onClick={() => setShowMenu(!showMenu)}
            />
          ) : (
            LINKS.map((link) => (
              <NavLink key={link.to} to={link.to}>
                {link.title}
              </NavLink>
            ))
          )}
        </RightContainer>
      </Content>
    </Container>
  );
}
