import { NavLink } from "react-router-dom";
import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedSectionSubTitle,
  SharedSectionTitle,
} from "../../components/Typography";
import { CAREERS_LIST } from "./data";
const Container = styled(SharedContainer)`
  background-image: none;
`;

const Content = styled(SharedContent)`
  padding-top: 60px;
  padding-bottom: 60px;
  flex-direction: column;
  text-align: center;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const List = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  align-items: center;
  width: 793px;
  @media only screen and (max-width: 873px) {
    width: 537px;
  }

  @media only screen and (max-width: 607px) {
    justify-content: center;
  }
`;

const ListItem = styled.div`
  background-color: white;
  padding: 20px;
  width: 250px;
  height: 230px;
  margin: 7px;
  box-shadow: 2px 1px 20px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  text-align: left;
  position: relative;
  @media only screen and (max-width: 607px) {
    width: 100%;
  }
`;
const ListTitle = styled(NavLink)`
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
  display: block;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const ListDesc = styled.span`
  font-size: 14px;
  display: block;
  margin: 4px 0;
`;

const Button = styled.button`
  position: absolute;
  bottom: 20px;
  margin-top: 20px;
  width: 120px;
  background-color: transparent;
  border: 1px solid #46e08d;
  padding: 7px;
  border-radius: 4px;
  color: #46e08d;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #46e08d;
    color: white;
  }
`;

export default function Hiring() {
  return (
    <Container>
      <Content>
        <SharedSectionSubTitle>WE ARE HIRING</SharedSectionSubTitle>
        <SharedSectionTitle>Join The Best. Be The Best </SharedSectionTitle>
        <ListContainer>
          <List>
            {CAREERS_LIST.map((listItem) => (
              <ListItem key={listItem.id}>
                <ListTitle to={`/careers/${listItem.id}`}>
                  {listItem.title}
                </ListTitle>
                {listItem.descList.map((item) => (
                  <ListDesc key={item}>{item}</ListDesc>
                ))}
                <NavLink to={`/careers/${listItem.id}`}>
                  <Button>View</Button>
                </NavLink>
              </ListItem>
            ))}
          </List>
        </ListContainer>
      </Content>
    </Container>
  );
}
