import styled from "styled-components";

export const SharedBannerTitle = styled.h1`
  font-size: 38px;
  color: white;
  max-width: 450px;
  margin: 30px 0 30px 0;
  font-family: "Poppins", sans-serif;
  z-index: 9;
  @media only screen and (max-width: 475px) {
    font-size: 30px;
    max-width: 100%;
  }
  @media only screen and (max-width: 389px) {
    font-size: 24px;
  }
`;
export const SharedBannerSubTitle = styled.h2`
  font-size: 14px;
  color: white;
  margin-top: 40px;
`;
export const SharedSectionSubTitle = styled.h2`
  font-size: 14px;
  color: #64e494;
  font-weight: 500;
`;
export const SharedSectionTitle = styled.h1<{ color?: string }>`
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  margin-top: -10px;
  color: ${({ color }) => color || "black"};
  margin-bottom: 60px;

  @media only screen and (max-width: 475px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 389px) {
    font-size: 22px;
  }
`;
export const SharedListTitle = styled.h4`
  color: white;
`;
