import React from "react";
import ReactDOM from "react-dom/client";
import Routes from "./containers/Routes";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./globalStyles";
import { ThemeProvider } from "styled-components";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <ThemeProvider theme={{}}>
    <React.StrictMode>
      <GlobalStyles />
      <Routes />
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
