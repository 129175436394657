import React from "react";

import Banner from "./Banner";
import WhatWeDo from "./WhatWeDo";
import Projects from "./Projects";
import Testimonials from "./Testimonials";
import Tools from "../Careers/Tools";
import Team from "./Team";
import Hiring from "../Careers/Hiring";

export default function Homepage() {
  return (
    <div>
      <Banner />
      <WhatWeDo />
      <Projects />
      <Testimonials />
      <Team />
      <Hiring />
      <Tools />
    </div>
  );
}
