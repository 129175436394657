import Banner from "./Banner";
import Description from "./Description";
import Tools from "../Careers/Tools";
import Benefits from "./Benefits";
import Team from "../Homepage/Team";

export default function Homepage() {
  return (
    <div>
      <Banner />
      <Description />
      <Benefits />
      <Team />
      <Tools />
    </div>
  );
}
