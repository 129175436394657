import styled from "styled-components";

export const SharedContainer = styled.div`
  background-image: linear-gradient(to bottom right, #5449e3, #39a6e3);
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const SharedContent = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex: 1;

  @media only screen and (max-width: 580px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

