import { useParams } from "react-router-dom";
import styled from "styled-components/";
import { SharedContainer, SharedContent } from "../../components/Layout";
import { SharedSectionTitle } from "../../components/Typography";
import { CAREERS_LIST } from "../Careers/data";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Container = styled(SharedContainer)`
  background-image: none;
`;

const Content = styled(SharedContent)`
  padding-top: 60px;
  padding-bottom: 60px;
  flex-direction: column;
  text-align: center;
  margin-top: -100px;
  background-color: white;
  border-radius: 10px;
  z-index: 9;
`;

const ListContainer = styled.div`
  text-align: left;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  > * {
    line-height: 1.5;
  }
`;

export default function WhatWeDo() {
  const { id } = useParams();
  const listItem = CAREERS_LIST.find((item) => item.id === id);
  if (!listItem) {
    return null;
  }
  return (
    <Container>
      <Content>
        <SharedSectionTitle color="#64e494">
          {listItem.title}
        </SharedSectionTitle>
        <ListContainer>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {listItem.jobDescription}
          </ReactMarkdown>
        </ListContainer>
      </Content>
    </Container>
  );
}
