import React from "react";
import styled from "styled-components";
import { SharedContent } from "../../components/Layout";

interface itemType {
  id: number;
  title: string;
  desc: string;
  image: string;
}
interface itemPropsType {
  item: itemType;
}
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  &.wrapper1 {
    background-image: linear-gradient(to bottom right, #5449e3, #39a6e3);
    padding-top: 40px;
  }
  &.wrapper3 {
    background-image: linear-gradient(to bottom right, #5449e3, #39a6e3);
  }
  &.wrapper2 {
  }
`;
const Content = styled(SharedContent)`
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
    padding: 80px 20px;
    h1 {
      text-align: center;
    }
  }
`;

const MidContent = styled(Content)`
  @media only screen and (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const SharedBannerTitle = styled.h1`
  font-size: 30px;
  color: white;
  font-family: "Poppins", sans-serif;
  z-index: 9;
  @media only screen and (max-width: 475px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 389px) {
    font-size: 24px;
  }
`;

export const SharedBannerSubTitle = styled.h2`
  font-size: 18px;
  color: white;
  max-width: 500px;
`;
const Illustration = styled.img`
  height: 450px;
  z-index: 1;
  @media only screen and (max-width: 612px) {
    height: 350px;
  }
`;
const BlackTitle = styled(SharedBannerTitle)`
  color: black;
`;
const BlackSubTitle = styled(SharedBannerSubTitle)`
  color: black;
`;
const Banner: React.FC<itemPropsType> = ({ item }) => {
  return (
    <Container className={`wrapper${item.id}`}>
      {item.id === 1 || item.id === 3 ? (
        <Content>
          <div>
            <SharedBannerTitle>{item.title}</SharedBannerTitle>
            <SharedBannerSubTitle>{item.desc}</SharedBannerSubTitle>
          </div>

          <Illustration src={item.image} />
        </Content>
      ) : (
        <MidContent>
          <Illustration src={item.image} />
          <div>
            <BlackTitle>{item.title}</BlackTitle>
            <BlackSubTitle>{item.desc}</BlackSubTitle>
          </div>
        </MidContent>
      )}
    </Container>
  );
};

export default Banner;
