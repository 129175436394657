export const CAREERS_LIST = [
  {
    id: "rb23203",
    title: "Project Manager (English)",
    experience: "3+ year",
    descList: [
      "Experience: 3+ yrs",
      "Location: Narephat, Kathmandu",
      "English fluency preferred",
    ],
    jobDescription: `
## Position Overview:

As a Project Manager, you will be responsible for planning, executing, and closing projects, ensuring that they are completed on time and within scope. You will collaborate with cross-functional teams, manage resources, and communicate effectively with stakeholders. The ideal candidate will have strong project management skills, attention to detail, excellent communication abilities, and a high level of proficiency in English.

## Responsibilities:

1. **Project Planning:** Develop comprehensive project plans, outlining the scope, goals, timelines, and resources required.

2. **Execution and Monitoring:** Oversee the day-to-day progress of projects, ensuring that they stay on schedule and within scope. Proactively identify and address potential issues.

3. **Resource Management:** Coordinate with team members and allocate resources effectively to meet project requirements.

4. **Stakeholder Communication:** Maintain clear and open communication with stakeholders, providing regular updates on project status, milestones, and any potential risks.

5. **Client Interaction:** Confidently engage with clients through video calls, leading weekly meetings with both project team members and clients. Provide updates, discuss issues, and collaboratively plan the development process.

6. **Risk Management:** Identify and mitigate project risks, developing contingency plans as needed.

7. **Quality Assurance:** Ensure that project deliverables meet quality standards and client expectations.

8. **Documentation:** Maintain accurate and up-to-date project documentation, including project plans, status reports, and meeting minutes.

9. **Team Collaboration:** Foster a collaborative and positive team environment, encouraging effective communication and teamwork.

## Qualifications:

1. **Experience:** 3+ years of experience in project management, with a proven track record of successfully delivering projects on time and within scope.

2. **Communication Skills:**
- Excellent written and verbal communication skills in English.
- Confidence in leading video calls with clients and project team members.

3. **Project Management Skills:**
- Strong organizational and project management skills.
- Experience with project management tools and methodologies.

4. **Problem-Solving:**
- Proven ability to analyze and solve problems, adapting to changing project requirements.

5. **Leadership:**
- Leadership skills to guide and motivate team members toward project goals.

6. **Adaptability:**
- Ability to adapt to changing priorities and work effectively in a dynamic environment.

## Education:

Bachelor’s degree in Project Management, Business Administration, or a related field is preferred.

If you are an experienced Project Manager with a passion for delivering successful projects, strong communication skills in English, and confidence in leading client meetings, we encourage you to apply.`,
  },
  {
    id: "eb117379",
    title: "Senior Ruby on Rails developer",
    experience: "4+ year",
    descList: ["Experience: 4+ yrs", "Location: Narephat, Kathmandu"],
    jobDescription: `

## Position Overview:

As a Senior Ruby on Rails Developer, you will play a key role in designing, implementing, and maintaining robust and scalable web applications. You will work closely with cross-functional teams, including product managers, designers, and other developers, to deliver high-quality software solutions. The ideal candidate will have a deep understanding of Ruby on Rails, strong problem-solving skills, and a passion for delivering exceptional user experiences.

## Responsibilities:

1. **Architect and Develop:** Lead the design and development of complex features and enhancements using Ruby on Rails, ensuring scalability, performance, and maintainability.

2. **Collaborate:** Work closely with cross-functional teams to understand business requirements, translate them into technical specifications, and contribute to the overall product strategy.

3. **Code Review:** Conduct thorough code reviews, providing constructive feedback to maintain code quality and ensure adherence to best practices.

4. **Technical Leadership:** Mentor and guide junior developers, share knowledge with the team, and contribute to the overall technical direction of the projects.

5. **Project Management:** Manage the project entirely, taking full responsibility for its success. The candidate should be able to handle the project and the team effectively.

6. **Communication Skills:** Communicate with foreign clients weekly through video calls, ensuring clear and effective communication on project updates and requirements.

7. **Problem Solving:** Analyze and solve complex technical problems, providing innovative solutions and troubleshooting issues to ensure a seamless user experience.

8. **Quality Assurance:** Implement and maintain automated testing processes to ensure the reliability and stability of the applications.

9. **Stay Current:** Keep up-to-date with the latest industry trends, tools, and technologies and advocate for their integration into the development process.

## Qualifications:

1. **Experience:** 4+ years of professional software development experience with a focus on Ruby on Rails.

2. **Technical Skills:**
    - Strong proficiency in Ruby, Rails, and associated technologies.
    - Experience with front-end technologies such as JavaScript, HTML, CSS, and front-end frameworks (e.g., React, Vue.js).
    - Solid understanding of database design and query optimization (preferably with PostgreSQL).
    - Familiarity with RESTful API design and integration.

3. **Leadership Skills:**
    - Proven experience leading and mentoring development teams.
    - Strong communication skills with the ability to convey complex technical concepts to non-technical stakeholders.

4. **Problem-Solving:**
    - Exceptional problem-solving and critical-thinking skills.
    - Ability to troubleshoot and debug complex issues efficiently.

5. **Team Player:**
    - Collaborative mindset with the ability to work effectively in a team-oriented environment.

6. **Continuous Learner:**
    - Demonstrated commitment to ongoing learning and professional development.

## Education:

Bachelor’s degree in Computer Science, Software Engineering, or a related field is preferred.
If you are a skilled Ruby on Rails Developer looking to take on a leadership role in a dynamic and innovative company, we would love to hear from you.`,
  },
  {
    id: "d291c506",
    title: "Mid-Level Ruby on Rails developer",
    experience: "1 year",
    descList: ["Experience: 2+ yrs", "Location: Narephat, Kathmandu"],
    jobDescription: `
## Position Overview:

As a Mid-Level Ruby on Rails Developer, you will be an integral part of our development team, focusing on designing, implementing, and maintaining web applications. You will collaborate with cross-functional teams, participate in code reviews, and contribute to the continuous improvement of our software solutions.

## Responsibilities:

1. **Development:** Contribute to the design and implementation of features and enhancements using Ruby on Rails, ensuring code quality, scalability, and maintainability.

2. **Collaboration:** Work closely with cross-functional teams to understand project requirements and translate them into technical specifications.

3. **Code Review:** Participate in code reviews, providing valuable feedback to maintain code quality and adhere to best practices.

4. **Problem Solving:** Analyze and solve technical challenges, actively contributing to the resolution of issues to ensure a smooth user experience.

5. **Quality Assurance:** Assist in the implementation and maintenance of automated testing processes to uphold the reliability and stability of applications.

6. **Stay Current:** Keep abreast of industry trends, tools, and technologies, and apply this knowledge to improve development processes.

## Qualifications:

1. **Experience:** 2+ years of professional software development experience with a focus on Ruby on Rails.

2. **Technical Skills:**
    - Proficiency in Ruby, Rails, and associated technologies.
    - Familiarity with front-end technologies such as JavaScript, HTML, CSS, and front-end frameworks (e.g., React, Vue.js).
    - Understanding of database design and query optimization.

3. **Problem-Solving:**
    - Strong problem-solving and critical-thinking skills.

4. **Team Player:**
    - Collaborative mindset with the ability to work effectively in a team-oriented environment.

5. **Continuous Learner:**
    - Eagerness to learn and grow professionally in a dynamic development environment.

## Education:

Bachelor’s degree in Computer Science, Software Engineering, or a related field is preferred.

If you are a motivated Mid-Level Ruby on Rails Developer looking to contribute to innovative projects and grow your skills, we encourage you to apply.`,
  },
  // {
  //   id: "443a349d",
  //   title: "UI/UX developer",
  //   descList: ["Experience: 2+ yrs", "Location: Narephat, Kathmandu"],
  //   markdown: `
  //   ## Job Description
  //   - Conceptualizing visuals based on requirements
  //   - Creating user-centered designs by understanding business requirements, and user feedback
  //   - Proven graphic designing experience
  //   - Familiarity with design software and technologies (such as Photoshop, Illustrator, Figma, Adobe XD or Sketch)
  //   - A keen eye for aesthetics and details
  //   - Identifying and troubleshooting UX problems
  //   - Strong communication skill
  //   - Ability to perform as part of a team as well as individually
  //   `,
  // },
  // {
  //   id: "b624491f",
  //   title: "Quality Assurance developer",
  //   experience: "1 year",
  //   descList: ["Experience: 2+ yrs", "Location: Narephat, Kathmandu"],
  //   jobDescription: `
  //   ## Description
  //   - We are looking for a skilled QA Developer with at least 1 year of experience to join our team. As a QA Developer, you will be responsible for ensuring the quality of our software products through manual and automated testing.
  //   ## Responsibility
  //   - Develop and execute test cases, scripts, and plans to verify software functionality and performance
  //   - Identify, report, and track defects to resolution
  //   - Collaborate with cross-functional teams including product, design, and engineering
  //   - Participate in design and code reviews to ensure quality standards are met
  //   - Stay up-to-date with emerging trends and technologies in software testing
  //   ## Requirements
  //   - At least 1 year of experience as a QA Developer or similar role
  //   - Experience with manual and automated testing
  //   - Familiarity with testing frameworks such as Selenium, Appium, or Cypress
  //   - Strong understanding of software testing methodologies and best practices
  //   - Experience with defect tracking and reporting tools such as JIRA
  //   - Good communication and collaboration skills
  //   - Ability to work independently as well as in a team environment

  //   `,
  // },
  //   {
  //     id: "8beaa9ef",
  //     title: "Project Manager",
  //     experience: "4+ years",
  //     descList: ["Experience: 3+ yrs", "Location: Narephat, Kathmandu"],
  //     jobDescription: `
  // ## Description
  // -  We are seeking a skilled and motivated Project Manager to join our software company. The successful candidate will have at least 4+ years of experience in project management and a strong understanding of software development processes,
  // ## Responsibility
  // - Manage software development projects from initiation to closure
  // - Collaborate with cross-functional teams to ensure successful project delivery
  // - Create and maintain project plans, budgets, and schedules
  // - Identify and manage project risks and issues
  // - Communicate project status to stakeholders
  // - Ensure that project requirements are met and project deliverables are of high quality
  // - Continuously monitor project progress and adjust project plans as needed
  // - Maintain documentation related to the project
  // ## Requirements
  // - Bachelor's degree in Computer Science, Engineering, or a related field
  // - At least 4+ year of experience in project management in a software development environment
  // - Strong knowledge of project management methodologies, tools, and techniques
  // - Excellent communication and interpersonal skills
  // - Ability to work well in a team environmentt
  // - Strong problem-solving skills and attention to detail
  // - Proven track record of delivering projects on time and within budget`,
  //   },

  // {
  //   id: "1bc4fbe1",
  //   title: "Full Stack (React/Node) Developer",
  //   experience: "3 year",
  //   descList: ["Experience: 3+ yrs", "Location: Narephat, Kathmandu"],
  //   jobDescription: `
  //   ## Description
  //   - We are looking for a Full Stack React/Node Developer to join our team. The ideal candidate will have experience with React, Node.js, and databases such as MySQL or MongoDB. The candidate will be responsible for developing and maintaining web applications, as well as working on new projects from start to finish. The candidate should have strong communication skills, be a team player, and be able to work independently.
  //   ## Responsibilities:
  //   - Develop and maintain web applications using React and Node.js
  //   - Collaborate with designers, product owners, and other developers to create and implement new features
  //   - Write clean, efficient, and well-documented code
  //   - Optimize applications for maximum speed and scalability
  //   - Troubleshoot and debug issues as they arise
  //   - Stay up-to-date with emerging trends and technologies in web development
  //   ## Requirements:
  //   - Bachelor's degree in Computer Science or related field
  //   - At least 3 years of experience in React and Node.js development
  //   - Experience with databases such as MySQL or MongoDB
  //   - Strong understanding of JavaScript, TypeScript, HTML, and CSS
  //   - Familiarity with Agile development methodologies
  //   - Experience with Git or other version control systems
  //   - Experience with AWS or other cloud platforms
  //   - Experience with testing frameworks such as Jest or Mocha
  //   - Ability to work independently as well as in a team environment`,
  // },
  // {
  //   id: "bb6ad9dd",
  //   title: "Nodejs Developer",
  //   experience: "4+ years",
  //   descList: ["Experience: 4+ yrs", "Location: Narephat, Kathmandu"],
  //   jobDescription: `
  //     ## Description
  //     - We are looking for a skilled Node.js developer with at least 4 years of experience to join our team. You will be responsible for developing and maintaining server-side applications using Node.js
  //     ## Responsibility
  //     - Design, develop, and maintain server-side applications using Node.js
  //     - Developing high-performance applications by writing testable, reuseable, and efficient code
  //     - Work collaboratively with cross-functional teams including product, design, and engineering
  //     - Optimize applications for maximum speed and scalability
  //     - Troubleshoot and debug issues that arise
  //     - Understanding accessibility and security compliance

  //     ## Requirements
  //     - At least 4 years of experience as a Node.js developer
  //     - Expert-level skills in design, architecture and development, with an ability to take a deep dive in the implementation aspects
  //     - Understanding the nature of asynchronous programming and its quirks and workarounds
  //     - Strong communication skill
  //     - Strong proficiency with NodeJS, JavaScript, ES6, TypeScript, GraphQL and MongoDB
  //     - Strong proficiency with NoSQL,SQL database and schema design
  //     - Strong understanding of working with REST APIs.
  //     - Creating secure RESTful-based web services in XML and JSON.
  //     - Experience with cloud platforms such as AWS or Azure
  //     - Understanding fundamental design principles behind a scalable application
  //     - Experience with containerization technologies such as Docker and Kubernetes
  //     - Hands-on Knowledge in CI/CD for code deployment.`,
  // },

  // {
  //   id: "5a04783f",
  //   title: "React Native Developer",
  //   descList: ["Experience: 2+ yrs", "Location: Narephat, Kathmandu"],
  //   markdown: `
  //   ## Job Description
  //   - Developing high-performance mobile apps for the iOS and Android platforms
  //   - Architect, build and maintain excellent React Native applications with clean code.
  //   - Create interchangeable front-end modules by writing reusable, effective, and scalable JavaScript code.
  //   - Implement clean, modern, smooth animations and transitions that provide an excellent user experience.
  //   - Integrate third-party API's.
  //   - Write unit and integration tests.
  //   - Work as part of a small team, which will include other React Native developers, a project manager, QA professional, and a designer.
  //   - Release applications to the Apple and Google Play stores.
  //   - Strong communication skill
  //   - Ability to perform as part of a team as well as individually
  //   `,
  // },
  {
    id: "3ec7a759",
    title: "React/React Native Developer",
    experience: "3+ years",
    descList: ["Experience: 3+ yrs", "Location: Narephat, Kathmandu"],
    jobDescription: `
## Description
- We are seeking a talented React/React Native Developer with at least 3 years of experience to join our growing team. You will be responsible for developing and maintaining mobile and web applications using React and React Native.
## Responsibility 
- Design, develop, and maintain mobile and web applications using React and React Native
- Write clean, efficient, and reusable code
- Work collaboratively with cross-functional teams including product, design, and engineering
- Optimize applications for maximum speed and scalability
- Troubleshoot and debug issues that arise
- Stay up-to-date with emerging trends and technologies in software development

## Requirements
- At least 3 years of experience as a React/React Native developer
- Strong proficiency in JavaScript, TypeScript and ES6+
- Experience with Redux, Saga or Thunk
- Experience with mobile application development using React Native
- Familiarity with RESTful APIs and microservices architecture
- Experience with version control systems such as Git
- Good understanding of UI/UX principles and best practices
- Good communication and collaboration skills
    `,
  },

  {
    id: "03ba3a92",
    title: "Quality Assurance",
    experience: "2 year",
    descList: ["Experience: 2+ yrs", "Location: Narephat, Kathmandu"],
    jobDescription: `
    ## Description
    - We are looking for a skilled QA Developer with at least 1 year of experience to join our team. As a QA Developer, you will be responsible for ensuring the quality of our software products through manual and automated testing.
    ## Responsibility
    - Develop and execute test cases, scripts, and plans to verify software functionality and performance
    - Identify, report, and track defects to resolution
    - Collaborate with cross-functional teams including product, design, and engineering
    - Participate in design and code reviews to ensure quality standards are met
    - Stay up-to-date with emerging trends and technologies in software testing
    ## Requirements
    - At least 1 year of experience as a QA Developer or similar role
    - Experience with manual and automated testing
    - Familiarity with testing frameworks such as Selenium, Appium, or Cypress
    - Strong understanding of software testing methodologies and best practices
    - Experience with defect tracking and reporting tools such as JIRA
    - Good communication and collaboration skills
    - Ability to work independently as well as in a team environment
    `,
  },
];
