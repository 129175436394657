import React from "react";
import styled from "styled-components";
import { SharedContainer, SharedContent } from "../../components/Layout";
import {
  SharedBannerTitle,
  SharedBannerSubTitle,
} from "../../components/Typography";
const Container = styled(SharedContainer)`
  height: 590px;
`;
const Content = styled(SharedContent)`
  justify-content: space-between;
  align-items: center;
`;
const GreenDesc = styled.span`
  font-family: "Poppins", sans-serif;
  color: #64e494;
`;
const Line = styled.div`
  height: 2px;
  width: 30px;
  background-color: #64e494;
`;
const Illustration = styled.img`
  height: 400px;
  z-index: 1;
  @media only screen and (max-width: 974px) {
    display: none;
  }
`;
const Banner = () => {
  return (
    <Container>
      <Content>
        <div>
          <SharedBannerSubTitle>Get In Touch</SharedBannerSubTitle>
          <Line />
          <SharedBannerTitle>
            Find Us <GreenDesc>On The Heart Of The City </GreenDesc>
          </SharedBannerTitle>
        </div>
        <Illustration src="/illustrations/map.png" />
      </Content>
    </Container>
  );
};

export default Banner;
