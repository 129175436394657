import styled from "styled-components";
import React from "react";
import { NavLink } from "react-router-dom";
import { SharedContainer, SharedContent } from "./Layout";
import {} from "./Typography";
const Container = styled(SharedContainer)`
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  background-image: none;
`;
const Content = styled(SharedContent)`
  justify-content: space-between;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-wrap: wrap;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const FooterBottom = styled.div`
  padding: 10px 0px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: black;
`;

const RightSection = styled.div`
  @media only screen and (max-width: 670px) {
    > p {
      display: none;
    }
  }
`;
const Column = styled.div`
  a,
  a:hover,
  a:visited,
  a:active {
    display: block;
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }
  margin-bottom: 10px;
`;

const ColumnTitle = styled.p`
  color: white;
`;

const FooterBottomText = styled.p`
  color: white;
  font-size: 14px;
`;

const Logo = styled.img`
  height: 30px;
`;

const LINKS = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "Careers",
    to: "/careers",
  },
  // {
  //   title: "Case Studies",
  //   to: "/case-studies",
  // },
];

export default function Footer() {
  return (
    <>
      <Container>
        <Content>
          <RightSection>
            <Logo src="/logo-white.png" alt="Intosoft logo" />
            <p
              style={{
                maxWidth: 200,
                fontSize: 14,
              }}
            >
              Your technology partner. Build great products with us.
            </p>
          </RightSection>
          <Column>
            <ColumnTitle>Links</ColumnTitle>
            {LINKS.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                style={{
                  marginBottom: 7,
                }}
              >
                {link.title}
              </NavLink>
            ))}
          </Column>
          <div>
            <p
              style={{
                fontSize: 16,
              }}
            >
              Address
            </p>
            <p
              style={{
                fontSize: 14,
                marginTop: -7,
                marginBottom: 30,
              }}
            >
              Narephat, Koteshwor-32, Kathmandu
            </p>
            <p
              style={{
                fontSize: 16,
              }}
            >
              Contact
            </p>
            <p
              style={{
                fontSize: 14,
                marginTop: -7,
              }}
            >
              +977-9769761859
            </p>
            <p
              style={{
                fontSize: 14,
                marginTop: -7,
              }}
            >
              hi@intosoft.com
            </p>
          </div>
        </Content>
      </Container>
      <FooterBottom>
        <FooterBottomText>Intosoft | All rights reserved</FooterBottomText>
      </FooterBottom>
    </>
  );
}
